import {
  View,
  Image,
  ScrollView,
  useWindowDimensions,
  ScaledSize,
  RefreshControl
} from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";

import {
  getNameOrUsername,
  isFalsy,
  unmaskPhoneNumber
} from "common/helpers/helpers";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import IconButton from "../../components/ui/IconButton";
import Spacing from "../../components/ui/Spacing";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import { staffDoctor, staffHealthWorker } from "../../assets/common";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import { Linking_openURL } from "../../helpers/LinkingHelper";
import { dispatch } from "common/redux";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import UserTypeInner from "common/types/UserTypeInner";
import { blue } from "common/styling/colors";
import TouchableOpacity from "../../components/ui/TouchableOpacity";

interface ActionButtonProps {
  action: string;
  icon: string;
  onPress: () => void;
}

const ActionButton = ({ action, icon, onPress }: ActionButtonProps) => {
  const { theme } = useTheme();
  const textStyles = useTextStyles();
  return (
    <IconButton
      shape="square"
      style={{ flex: 1 }}
      color={theme.colors.tealBlue}
      icon={icon}
      iconColor={theme.colors.white}
      iconSize={32}
      buttonSize={96}
      onPress={onPress}
    >
      <Spacing vertical={2} />

      <Text
        capsSmall
        allowFontScaling={false}
        style={[textStyles.colorWhite, textStyles.textAlignCenter]}
      >
        {action}
      </Text>
    </IconButton>
  );
};

interface ListItemProps {
  user: UserTypeInner;
  role: string;
}

const ListItem = ({ user, role }: ListItemProps) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  const image = role === "NURSE" ? staffHealthWorker : staffDoctor;
  const phoneDisabled = isFalsy(user.mobile ?? user.phone);
  return (
    <TouchableOpacity
      style={styles.listItemContainer}
      onPress={() => {
        const phone = unmaskPhoneNumber(user.mobile ?? user.phone);
        const phoneUrl = `tel:+1${phone}`;
        Linking_openURL(phoneUrl, dispatch);
      }}
      disabled={phoneDisabled}
    >
      <Image source={image} style={styles.listItemDoctorImage} />
      <View>
        <Text capsLarge style={textStyles.colorDarkGreyBlue}>
          {getNameOrUsername(user, false, true, true)}
        </Text>
        <Text capsSmall style={textStyles.colorDarkGreyBlue}>
          {role}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const YourCareTeamScreen = () => {
  const dimensions = useWindowDimensions();
  const textStyles = useTextStyles();
  const styles = useStyles(dimensions);
  const {
    data: member,
    isLoading,
    isUninitialized,
    refetch,
    isFetching
  } = useGetAuthenticatedMember(false, [
    MemberLinkedEntitiesEnum.NURSE,
    MemberLinkedEntitiesEnum.PROVIDER
  ]);

  const hasCarers =
    member?.assigned_nurse !== undefined ||
    member?.patient_provider !== undefined;

  const handleRefresh = () => {
    if (isUninitialized) return;
    refetch();
  };

  return (
    <ScreenContainer>
      <Spacing vertical={4} />

      <ScrollView
        contentContainerStyle={styles.container}
        refreshControl={
          <RefreshControl
            refreshing={isLoading === false && isFetching}
            onRefresh={handleRefresh}
          />
        }
      >
        <View style={styles.gridContainer}>
          <View style={styles.gridItem}>
            <Text
              h4
              style={[textStyles.colorDarkGreyBlue]}
              accessibilityLabel={"Your Care Team Message"}
            >
              {LocalizedStrings.screens.yourCareTeam.header}
            </Text>
            <Spacing vertical={2} />

            <Text
              body
              style={[textStyles.colorDarkGreyBlue]}
              accessibilityLabel={"Your Care Team Message"}
            >
              {LocalizedStrings.screens.yourCareTeam.content}
            </Text>

            <Spacing vertical={4} />
            <View style={styles.buttonRow}>
              <ActionButton
                icon="phone-call"
                action={LocalizedStrings.screens.yourCareTeam.callUs}
                onPress={() => {
                  let phone = unmaskPhoneNumber("+1 (888) 920-1588");
                  // if unmasked number doesn't have country code, add it
                  if (!phone.includes("+1")) phone = "+1" + phone;
                  const phoneUrl = `tel:${phone}`;
                  Linking_openURL(phoneUrl, dispatch);
                }}
              />
            </View>
            <Spacing vertical={2} />
          </View>

          {isLoading === true && (
            <View style={styles.gridItem}>
              <ActivityIndicator />
            </View>
          )}
          {hasCarers && (
            <View style={styles.gridItem}>
              <Text
                h4
                style={[textStyles.colorDarkGreyBlue]}
                accessibilityLabel={"Your Care Team Title"}
              >
                {LocalizedStrings.screens.yourCareTeam.title}
              </Text>

              <Spacing vertical={2} />
              <View style={styles.gap}>
                {member?.assigned_nurse && (
                  <ListItem user={member?.assigned_nurse} role={"NURSE"} />
                )}
                {member?.patient_provider && (
                  <ListItem user={member?.patient_provider} role={"PROVIDER"} />
                )}
              </View>
            </View>
          )}
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme, dimensions: ScaledSize) => {
  const screenWidth = dimensions ? dimensions.width : 10000;

  return {
    flex1: {
      flex: 1
    },
    container: {
      marginHorizontal: 20
    },
    buttonRow: {
      flexDirection: "row",
      gap: 20,
      justifyContent: "space-evenly"
    },
    alignCenter: {
      alignSelf: "center"
    },
    gap: {
      gap: 20
    },
    gridContainer: {
      flexWrap: "wrap",
      flexDirection: "row",
      gap: 20,
      flex: 1,
      marginBottom: 20
    },
    gridItem: {
      minWidth: Math.min(screenWidth - 50, 350),
      flex: 1
    },
    listItemContainer: {
      flexDirection: "row",
      alignItems: "center",
      borderColor: theme.colors.tealBlue,
      borderWidth: 1,
      borderRadius: 20,
      backgroundColor: blue[50]
    },
    listItemDoctorImage: {
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.tealBlue,
      width: 72,
      height: 72,
      margin: 10
    }
  };
});

export default YourCareTeamScreen;
