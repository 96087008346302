// Credits to https://github.com/alexkorep/react-native-bluetooth-headset-detect/blob/master/index.js

import { NativeEventEmitter, NativeModules, Platform } from "react-native";
import { useEffect, useState } from "react";
import { PermissionsHelper_askForBluetooth } from "../helpers/PermissionsHelper";
import { SentryHelper_captureException } from "../helpers/SentryHelper";

const BluetoothHeadsetDetectModule = NativeModules.BluetoothHeadsetDetect;
const bluetoothHeadsetDetectEmitter = new NativeEventEmitter(
  BluetoothHeadsetDetectModule
);

let device = null;
const listeners = [];

bluetoothHeadsetDetectEmitter.addListener("onChange", ({ devices }) => {
  device = devices.length ? devices[0] : null;
  listeners.forEach((listener) => {
    listener(device);
  });
});

// Events
const addListener = (listener) => {
  listeners.push(listener);
  if (Platform.OS === "android") {
    BluetoothHeadsetDetectModule.addListener();
  }
};
const removeListener = (listener) => {
  const idx = listeners.indexOf(listener);
  if (idx === -1) {
    return;
  }
  listeners.splice(idx, 1);
  if (Platform.OS === "android") {
    BluetoothHeadsetDetectModule.removeListener();
  }
};

// React hook
export const useBluetoothHeadsetDetection = (skip = false) => {
  const [headset, setHeadset] = useState<string>(null);
  const [permissionsAccepted, setPermissionsAccepted] = useState<boolean>(
    Platform.OS !== "android"
  );

  useEffect(() => {
    if (skip) return;

    if (Platform.OS === "android") {
      PermissionsHelper_askForBluetooth()
        .then(() => setPermissionsAccepted(true))
        .catch((error) =>
          SentryHelper_captureException(
            "BluetoothHeadsetDetect permission exception",
            {
              level: "debug",
              tags: error
            }
          )
        );
    }
  }, [skip]);

  useEffect(() => {
    if (permissionsAccepted === false) return;
    setHeadset(device);
    addListener(setHeadset);
    return () => {
      removeListener(setHeadset);
    };
  }, [permissionsAccepted]);

  return headset;
};
