import { RootState } from "common/redux";
import { useSelector } from "react-redux";
import { useGetMemberWithUsernameQuery } from "../services/MemberService";
import MemberLinkedEntitiesEnum from "../enums/MemberLinkedEntitiesEnum";

const useGetAuthenticatedMember = (
  forceSkip?: boolean,
  linked_entities?: MemberLinkedEntitiesEnum[]
) => {
  const { hasCredentials, user } = useSelector(
    (state: RootState) => state.auth
  );

  const queryResult = useGetMemberWithUsernameQuery(
    { username: user?.email!, linked_entities },
    {
      skip: hasCredentials === false || user?.email === undefined || forceSkip
    }
  );

  return queryResult;
};

export default useGetAuthenticatedMember;
