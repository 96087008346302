// We will need to update this if we want multilanguage support.
const language = {
  common: {
    next: "Next",
    continue: "Continue",
    submit: "Submit",
    close: "Close",
    retry: "Retry",
    tryAgain: "Try again",
    cancel: "Cancel",
    confirm: "Confirm",
    save: "Save",

    and: "and",
    phoneNumber: "Phone Number",
    dateOfBirth: "Date of Birth",
    enable: "Enable",
    yes: "Yes",
    no: "No",
    yesConfirm: "Yes, confirm",
    goBack: "Go back",
    noGoBack: "No, go back",

    join: "Join",
    hello: "Hello",
    finish: "Finish",
    modify: "Modify",
    delete: "Delete",
    back: "Back",

    fromDate: "From Date",
    toDate: "To Date",

    appVersion: {
      lastUpdated: "Last Updated:",
      version: "Version:",
      buildNumber: "Build Number:",
      upgrade: "Upgrade"
    },
    bloodPressure: "Blood Pressure",
    bloodSugar: "Blood Sugar",
    glucoseReadings: "Glucose readings",

    week: "Week",
    year: "Year",
    clear: "Clear",
    reset: "Reset",

    units: {
      mmHg: "mmHg",
      bpm: "bpm",
      glucoseUnit: "mg/dL"
    },

    validations: {
      required: "Required",
      betweenCharacters:
        "Please enter a value between {{FROM}} and {{TO}} characters.",
      upToCharacters: "Please enter a value up to {{TO}} characters.",
      validState: "Please enter a valid US state abbreviation.",
      validPostalCode: "Please enter a valid US postal code.",
      phoneNumber: "Please enter a valid 10-digit phone number",
      mobileNumber: "Please enter a valid 10-digit mobile number",
      invalidEmail: "Invalid email address"
    }
  },

  screens: {
    // Non authenticated flow
    landing: {
      signUp1: "Not a member?",
      signUpLink: "Learn more",
      onboarding1: "Understand your readings and progress easily.",
      onboarding2: "Get custom recommendations from your care team.",
      onboarding3: "Set and achieve your goals in a healthy way.",
      joiningAVideoCall: "Joining a video call?"
    },
    reauthentication: {
      welcome: "Welcome, ",
      notActive:
        "You have not been active in the app for 30+ days, so we need to verify your account for security.",
      button: "Let’s Begin"
    },
    phoneInput: {
      title: "Login Start",
      phoneAndDOB:
        "Please enter your phone number and date of birth so we can securely verify you."
    },
    smsCode: {
      title: "Login Details",
      codeSent: "We just sent you a new code.",
      text: "To secure your information, we sent a one-time code by text message to your phone {{PHONE_NUMBER}} to verify your identity.",
      inputTitle: "SMS SECURITY CODE",
      secondsRemaining: "{{SECONDS}} seconds remaining",
      resendCode: "Resend Code"
    },
    missingLegalForms: {
      title: "Login Consents",
      header:
        "Please review and check the boxes below to complete the required patient consents:",
      iAgreeToThe: "I agree to the",
      iAgreeTo: "I agree to",
      iAgreeToReceive: "I agree to receive",

      termsOfService: "Terms of Service",
      privacyPolicy: "Privacy Policy",
      noticeOfPrivacyPractices: "Notice of Privacy Practices",
      telehealthInformedConsent: "Telehealth Informed Consent",
      remotePatientMonitoringInformedConsent:
        "Remote Patient Monitoring Informed Consent",
      assignmentOfBenefits: "Assignment of Benefits",
      textMessagesFromCopilot: "text messages (SMS) from CopilotIQ",
      agreeAndContinue: "Agree and Continue"
    },
    biometrics: {
      title: "Biometrics",
      verifyYourIdentity: "Verify your identity",
      identityVerified: "Identity verified!",
      useSensorToLogIn:
        "Use {{BIOMETRICS_SENSOR_NAME}} to log in quickly and securely",

      sensorNameBiometrics: "Biometrics",
      sensorNameFaceID: "Face ID",
      sensorNameTouchID: "Touch ID",
      notSupported: "Biometrics is not supported on this device"
    },
    turnOnNotifications: {
      title: "Notifications",
      header: "Turn on your notifications!",
      text1:
        "We will use notifications to guide you through the app and its functionalities.",
      text2:
        "The majority of members who reach their goals have their notifications on!",
      rejected:
        "Notification permissions are rejected. In order to enable them you must visit your device's settings",
      openSettings: "Open Settings",
      turnOn: "Turn Notifications On",
      later: "Maybe Later"
    },
    joinVideoCall: {
      title: "Join Video Call",
      header: "Already on the phone with our team?",
      enterCode: "Enter provided code to join the call",
      placeholder: "Room Code",
      joinCall: "Join Call"
    },
    videoCall: {
      title: "Video Chat",
      callBeingRecorded:
        "This call is being recorded for quality and care purposes",
      callEndedModalTitle: "Call has ended",
      callEndedModalContent: "This video call has ended.",
      callEndModalTitle: "End Call",
      callEndModalContent: "Are you sure you want to end this call?",
      notSupported: "Video visits are not supported on this browser.",
      downloadApp: "Please download the CopilotIQ mobile app",
      orUseSupportedBrowser:
        "or use a supported browser (Chrome, Safari or Firefox)",
      errorAcceptPermissions:
        "Please accept both audio and video permissions to connect to your CopilotIQ appointment.",
      openSettings: "Go to settings"
    },

    // Authenticated flow
    tab: {
      home: "Home",
      readings: "Readings",
      appointments: "Appointments",
      account: "Account",
      yourVisits: "Your Visits"
    },

    home: {
      title: "Dashboard",
      ongoingCall: "Ongoing Call:",
      readingsBP: "BP",
      readingsPulse: "PULSE",
      readingsGlucose: "GLUCOSE",
      latestReadings: "Latest readings",
      noReadingsText:
        "Once you start using your device, the latest readings will appear here.",
      learnMore: "Learn More",
      tapReadingToViewMore: "Tap each reading to view more"
    },
    yourCareTeam: {
      title: "Your Care Team",
      needHelp: "Get to know your care team and get in touch",
      header: "Need help? Get in touch.",
      content:
        "Your team is here to assist you in every step of your health journey, and help is always a click away.",
      callUs: "TAP HERE TO CALL US"
    },
    confirmAppointment: {
      title: "Confirm Appointment",
      header: "Confirm your attendance?",
      confirmedHeader:
        "You have confirmed your attendance to the following appointment:",
      subHeader:
        "You are about to confirm your attendance to the following appointment:",
      returnToPreviousScreen: "Return to previous screen",
      addToCalendar: "Add to Calendar",
      thankYou:
        "Thank you, your nurse is looking forward to catching up with you!",
      rowDate: "Date",
      rowTime: "Time",
      rowWith: "With"
    },
    bookAppointment: {
      title: "Book Appointment",
      noCarersFound: "No Carers found",
      noAppointmentsFound:
        "We’re sorry, but there are no other available times with this nurse at the moment.",
      selectDate: "Choose a new time for your appointment with {{DOCTOR_NAME}}",
      selectDateOtherNurse: "Select a new date to have your appointment",
      listHeader:
        "This is a list of the options available to have an appointment with your nurse, select the most suitable for you.",
      needFasterResponse: "Need faster response?",
      sendAMessage: "Send a message to {{NAME}}",

      needMoreHelpBanner:
        "If you need further help, tap here to contact your care team.",
      moreTimeBanner:
        "None of these times work? Tap here to find the next available nurse",
      moreTimeTitle: "None of these times work?",
      moreTimeContent:
        "If you’re unable to find a suitable time to reschedule with your usual Nurse, we can include times with other available nurses to select from in the list.",
      moreTimeButton: "Include all available nurses",
      differentNurse: "Different Nurse",

      addToCalendarNurseTitle:
        "CopilotIQ Nurse Appointment with {{DOCTOR_NAME}}",
      addToCalendarProviderTitle:
        "CopilotIQ Provider Appointment with {{DOCTOR_NAME}}",
      addToCalendarDescription:
        "Your upcoming appointment with {{DOCTOR_NAME}} Please remember to confirm your attendance or reschedule it on the CopilotIQ App."
    },
    bookAppointmentConfirmation: {
      title: "Please Confirm",
      header: "You have selected:",

      appointmentConfirmed: "Appointment confirmed!",
      addToCalendar: "Add to Calendar"
    },
    myAppointments: {
      title: "My Appointments",
      text1: "Find a list of upcoming appointments below.",
      text2:
        "Here you can view your upcoming appointments in the next 90 days.",
      footerText: "Do you need a meeting with your provider or nurse?",
      bookAppointment: "Book Appointment",
      noDataTitle:
        "You have no upcoming appointments scheduled in the next {{DAYS}} days.",
      confirmedHeader: "Attendance confirmed",
      confirmedContent:
        "We'll remind you when the appointment is about to happen",
      startingSoonHeader: "Starting Soon!",
      startingSoonContent:
        "Your nurse will be contacting you sometime within this window."
    },
    rateAppointment: {
      title: "Review the Appointment",
      thankYouForTheFeedback: "Thank you for the feedback!"
    },
    goals: {
      title: "My Goals",
      addNewGoal: "Add New Goal",
      targetDate: "TARGET DATE",
      creationDate: "CREATION DATE",
      createdBy: "Created by",
      detail: "Detail",
      progress: "Progress",
      deleteModalTitle: "Do you want to delete {{TITLE}} goal?",
      deleteModalContent:
        "We will send your request to your nurse so you can both discuss this in your next appointment.",
      editGoal: "Modify Goal",
      editGoalHeader: "Suggest an edit for your {{TITLE}} goal",
      editGoalHeader2:
        "Your nurse will receive your suggestion and provide feedback.",
      editGoalSuccessTitle:
        "You have successfully suggested an edit for your {{TITLE}} goal.",
      editGoalSuccessContent:
        "You have successfully suggested an edit for your {{TITLE}} goal.",
      selectCategory: "Select a category for your new goal",
      createGoalSuccessTitle: "You have successfully created {{TITLE}} goal.",
      createGoalSuccessContent:
        "We will send the new goal to your nurse for review."
    },
    myReadings: {
      title: "Readings",
      header: "Take a look at your readings and progress",
      noReadingsYet: "You have not taken any readings yet",
      onceYouTakeReading:
        "Once you take your first reading, your progress will be displayed here.",
      needGuidance:
        "Need guidance on taking your first reading? Please call our nursing team",

      increase: "INCREASE",
      decrease: "DECREASE",
      noChange: "NO CHANGE",
      overTheLastDays: "over the last {{DAYS}} days",
      viewProgress: "View Progress",
      notEnoughData: "Not enough data available to display chart",
      rotateDevice1: "PLEASE ROTATE YOUR",
      rotateDevice2: "DEVICE TO THE RIGHT"
    },
    readingDetails: {
      latestReading: "Latest Reading",
      days: "% ({{DAYS}} days)",
      fullScreen: "Full Screen",
      seeAllReadings: "See all readings",
      min: "Min",
      avg: "Avg",
      max: "Max",
      noReadingsTaken: "No readings taken",
      history: "History",
      error: "An error has occurred loading your data. Please try again"
    },
    readingsHistory: {
      title: "Readings History",
      openCalendar: "Open Calendar",
      historyText: "History shows most recent reading first."
    },
    myAccount: {
      title: "Account",
      settings: "Settings",
      logOut: "Log Out",
      logOutModalTitle: "Confirmation",
      logOutModalContent: "Are you sure you want to log out?",
      offTime: "Your off-time (i.e vacation)"
    },
    offTime: {
      title: "Your off-time",
      content:
        "If you wish to skip all of your appointments within a certain period of time, perhaps for a vacation, please let us know.",
      addNew: "Add New",
      history: "History",
      listFrom: "From",
      listTo: "To",
      listAddedOn: "Added on",
      listInfo: "Info",
      listAffectedAppointments: "_NUMBER_ Affected Appointments",
      deleteModalTitle: "Delete off-time?",
      deleteModalContent:
        "You are about to delete your added off-time, all the appointments that were affected will be back on your schedule."
    },
    addEditOffTime: {
      titleAdd: "Add off-time",
      titleEdit: "Edit off-time",
      headerAdd: "You’re adding an off-time submission.",
      headerEdit: "You’re editing an off-time submission.",
      content: "All of your appointments within this period will be cancelled.",
      affectedAppointments: "Affected appointments:",

      errorToDateMustBeAfterFromDate: "Ending date must be after from date."
    },
    help: {
      title: "Help",
      faqs: "FAQs"
    },
    settings: {
      title: "Settings",
      verifyYourIdentity: "Verify your identity",
      notifications: "Notifications",
      notificationsDescription: "Enable to receive notifications",
      useOSTheme: "Use OS Theme",
      useOSThemeDescription: "Enable to use OS Theme",
      darkTheme: "Dark Theme",
      darkThemeDescription: "Enable to use Dark Theme",
      carePreferences: "Care Preferences",
      carePreferencesDescription:
        "When would you prefer to have care calls scheduled?",
      carePreferencesCadence: "CADENCE",
      carePreferencesDays: "DAYS",
      carePreferencesTimes: "TIMES",
      carePreferencesSaved: "Saved Sucessfully"
    },
    personalInformation: {
      title: "Personal Information",
      button: "Update Personal Information",
      firstName: "First Name",
      lastName: "Last Name",
      emailAddress: "E-mail address",
      phoneNumber: "Phone Number",
      mobileNumber: "Mobile Number",
      address: "Address",
      city: "City",
      zipCode: "Zip Code",
      state: "State",
      street1: "Street 1",
      street2: "Street 2"
    },
    familySharing: {
      title: "Family Sharing",
      text1: "Have loved ones that worry about you or help with your care?",
      text2: "You can share your readings with them!",
      familyMemberPlaceholder: "Family member's e-mail address",
      shareWithDoctor: "Share with Doctor"
    },
    doctorSharing: {
      title: "Doctor Sharing",
      doctorsName: "Doctor's name",
      sharedWith: "Shared With",
      shareWithDoctor: "Share with Doctor"
    },
    confirmSharing: {
      text1: "You’re going to share your data with:",
      giveConsent:
        "I give CopilotIQ permission to share my medical data with this person",
      confirmSharing: "Confirm Sharing",
      confirmSharingModalTitle: "We successfully added {{NAME}}",
      confirmSharingModalContent:
        "They will automatically receive your data every 90 days."
    },
    editPersonalInfo: {
      title: "Edit Personal Information",
      successModalTitle: "Personal Information Updated",
      successModalContent:
        "You have successfully updated your personal information.",
      saveChanges: "Save changes"
    }
  }
};

export default language;
