import { StyleProp, View, ViewStyle } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { makeStyles, useTheme } from "@rneui/themed";

import Spacing from "../Spacing";
import Text from "../Text";
import useTextStyles from "../styles/useTextStyles";
import LocalizedStrings from "../../../helpers/LocalizedStrings";
import useViewStyles from "../styles/useViewStyles";
import { isTruthy } from "common/helpers/helpers";

interface IProps {
  style?: StyleProp<ViewStyle>;
  days?: string;
}

const NoAppointmentCard = ({ style, days }: IProps) => {
  const { theme } = useTheme();
  const textStyles = useTextStyles();
  const viewStyles = useViewStyles();
  const styles = useStyles();

  return (
    <View style={[viewStyles.cardContainer, style]}>
      <Feather
        style={styles.iconContainer}
        name={"calendar"}
        color={theme.colors.darkGreyBlue}
        size={24}
      />
      <Spacing vertical={2} />
      <Text h4 style={[textStyles.colorGreyBlue]}>
        {isTruthy(days) &&
          LocalizedStrings.screens.myAppointments.noDataTitle.replace(
            "{{DAYS}}",
            days
          )}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    iconContainer: {
      borderRadius: 24,
      borderWidth: 1,
      borderColor: theme.colors.grey3,
      padding: 10,
      alignSelf: "flex-start"
    }
  };
});

export default NoAppointmentCard;
