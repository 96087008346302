import {
  ScaledSize,
  ScrollView,
  useWindowDimensions,
  View
} from "react-native";
import { makeStyles } from "@rneui/themed";
import { useNavigationState } from "@react-navigation/native";
import { DateTime } from "luxon";

import { useAppDispatch } from "common/redux";
import {
  useGetCalendarEventQuery,
  usePatientRescheduleCalendarEventMutation
} from "common/services/CalendarService";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { useGetUserWithUsernameQuery } from "common/services/UserService";

import ScreenContainer from "../../components/ui/ScreenContainer";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import Text from "../../components/ui/Text";
import LocalizedStrings from "../../helpers/LocalizedStrings";

import Spacing from "../../components/ui/Spacing";
import Button from "../../components/ui/Button";
import { useEffect, useState } from "react";
import { CalendarHelper_addEvent } from "../../helpers/CalendarHelper";

import {
  useHideTabBar,
  usePreventBackNavigation
} from "../../navigation/NavigationUtils";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import ConfirmAppointmentBox from "../../components/ui/ConfirmAppointmentBox";

import {
  getErrorMessage,
  getNameOrUsername,
  isFalsy
} from "common/helpers/helpers";
import ActivityIndicator from "../../components/ui/ActivityIndicator";

const BookAppointmentConfirmationScreen = ({ navigation, route }) => {
  const dimensions = useWindowDimensions();
  const textStyles = useTextStyles();
  const styles = useStyles(dimensions);
  const dispatch = useAppDispatch();
  const { type } = useScreenType();

  const { event_id, startDate, endDate, staff_id } = route.params;

  useHideTabBar(navigation, type !== ScreenTypeEnum.PHONE);

  const [isConfirmed, setConfirmed] = useState<boolean>(false);

  const state = useNavigationState((state) => state);

  const [
    patientRescheduleCalendarEvent,
    { isSuccess, isLoading: isMutationLoading, error: errorReschedule }
  ] = usePatientRescheduleCalendarEventMutation();

  const { data: appointment, error: errorCalendarEvent } =
    useGetCalendarEventQuery({
      event_id
    });

  usePreventBackNavigation(navigation, isMutationLoading);

  const {
    data: staff,
    isLoading,
    error: errorUser
  } = useGetUserWithUsernameQuery({
    username: staff_id
  });

  const staffName = getNameOrUsername(staff?.user, false, true, true);
  const luxonStartDate = DateTime.fromISO(startDate);
  const luxonEndDate = DateTime.fromISO(endDate);

  const error = errorUser ?? errorCalendarEvent ?? errorReschedule;

  useEffect(() => {
    if (isFalsy(appointment)) return;

    const appointmentStartDate = DateTime.fromISO(appointment.startdate);
    setConfirmed(appointmentStartDate.toISO() === luxonStartDate.toISO());
  }, [appointment]);

  useEffect(() => {
    if (isSuccess) {
      setConfirmed(isSuccess);
      const filteredRoutes = state.routes.filter(
        (route) => route.name === "BookAppointment"
      );
      navigation.reset({
        index: state.index - filteredRoutes.length,
        routes: state.routes.filter((route) => route.name !== "BookAppointment")
      });
    }
  }, [isSuccess]);

  const handleConfirm = () => {
    patientRescheduleCalendarEvent({
      event_id,
      staff_id,
      start_date: luxonStartDate,
      end_date: luxonEndDate
    });
  };

  const goBack = () => {
    navigation.goBack();
  };

  const handleAddToCalendar = () => {
    const title =
      appointment?.appointment_type === AppointmentTypeEnum.NURSE_FOLLOWUP
        ? LocalizedStrings.screens.bookAppointment.addToCalendarNurseTitle
        : LocalizedStrings.screens.bookAppointment.addToCalendarProviderTitle;
    setTimeout(
      () =>
        CalendarHelper_addEvent({
          title: title.replace("{{DOCTOR_NAME}}", staffName),
          description:
            LocalizedStrings.screens.bookAppointment.addToCalendarDescription.replace(
              "{{DOCTOR_NAME}}",
              staffName
            ),
          startDate: luxonStartDate,
          endDate: luxonEndDate,
          dispatch,
          onFinish: goBack
        }),
      1000
    );
  };

  return (
    <ScreenContainer>
      <ScrollView>
        <View style={styles.container}>
          {isLoading && <ActivityIndicator />}
          {staff && (
            <>
              <Text h3 style={textStyles.colorDarkGreyBlue}>
                {LocalizedStrings.screens.bookAppointmentConfirmation.header}
              </Text>
              <Spacing vertical={6} />
              <ConfirmAppointmentBox
                startDate={luxonStartDate}
                endDate={luxonEndDate}
                staffName={staffName}
              />
            </>
          )}

          <Spacing vertical={6} />

          {error && (
            <Text style={textStyles.colorError}>{getErrorMessage(error)}</Text>
          )}
          {staff && !isConfirmed && (
            <Button
              title={LocalizedStrings.common.yesConfirm}
              onPress={handleConfirm}
              loading={isMutationLoading}
            />
          )}
          <Spacing vertical={4} />
          <Button
            type="outline"
            disabled={isMutationLoading}
            title={
              isConfirmed
                ? LocalizedStrings.common.goBack
                : LocalizedStrings.common.noGoBack
            }
            onPress={goBack}
          />

          <Spacing vertical={2} />

          {isConfirmed && (
            <Button
              type="clear"
              onPress={handleAddToCalendar}
              title={LocalizedStrings.screens.confirmAppointment.addToCalendar}
              disabled={isFalsy(appointment)}
            />
          )}
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme, { width }: ScaledSize) => {
  return {
    container: {
      flex: 1,
      marginHorizontal: 20,
      paddingVertical: 20,
      maxWidth: Math.min(width - 20, 400),
      alignSelf: "center"
    }
  };
});

export default BookAppointmentConfirmationScreen;
