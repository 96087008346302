import { useCallback, useEffect, useMemo, useState } from "react";
import { FlatList, View, LayoutChangeEvent } from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";

import Feather from "react-native-vector-icons/Feather";
import { DateTime } from "luxon";

import { RouteProp, useFocusEffect, useRoute } from "@react-navigation/native";

import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import { ReadingQualifierReasonEnum_toString } from "common/enums/ReadingQualifierReasonEnum";
import { useGetReadingsByMemberQuery } from "common/services/ReadingsService";
import { ReadingAccessorType } from "common/hooks/useGetMemberDataSummary";

import ScreenContainer from "../../components/ui/ScreenContainer";
import useViewStyles from "../../components/ui/styles/useViewStyles";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import Chip from "../../components/ui/Chip";
import IconButton from "../../components/ui/IconButton";
import DateRangePickerModal from "../../components/modal/DateRangePickerModal";
import Chart from "../../components/readings/Chart";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import DeviceReadingType from "common/types/DeviceReadingType";
import { ReadingsStackParamList } from "../../navigation/ReadingsStackNavigator";
import RefreshControl from "../../components/ui/RefreshControl";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import Button from "../../components/ui/Button";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";
import { DeviceType_url_decode } from "common/helpers/helpers";
import TouchableOpacity from "../../components/ui/TouchableOpacity";
import LocalizedStrings from "../../helpers/LocalizedStrings";

interface ItemProps {
  testID: string;
  readingType: string;
  reading: DeviceReadingType;
}

const ListFooterAndSeparator = () => <Spacing vertical={4} />;

const ListItem = ({ testID, readingType, reading }: ItemProps) => {
  const { theme } = useTheme();
  const textStyles = useTextStyles();
  const viewStyles = useViewStyles();
  const styles = useStyles();

  const qualifierReasonString = ReadingQualifierReasonEnum_toString(
    reading.reading_qualifier
  );

  return (
    <View style={[viewStyles.cardContainer, styles.row]}>
      <View style={styles.flex1}>
        {readingType === DeviceTypeEnum.GLUCOSE_CATEGORY && (
          <View style={styles.rowOnly}>
            <Feather name={"droplet"} size={24} color={theme.colors.tealBlue} />
            <Spacing horizontal={1} />
            <Text
              testID={testID + "glucose"}
              h3
              style={textStyles.colorDarkBlue}
            >
              {reading.glucose}
              <Text testID={testID + "unit"} bodySmall>
                {" "}
                {LocalizedStrings.common.units.glucoseUnit}
              </Text>
            </Text>
          </View>
        )}

        {readingType === DeviceTypeEnum.BLOOD_PRESSURE && (
          <View>
            <View style={styles.rowOnly}>
              <Feather
                name={"activity"}
                size={24}
                color={theme.colors.tealBlue}
              />

              <Spacing horizontal={1} />
              <Text
                testID={testID + "systolic"}
                h3
                style={textStyles.colorDarkBlue}
              >
                {reading.systolic}/{reading.diastolic}{" "}
              </Text>
            </View>
            <Spacing vertical={1} />
            <View style={styles.rowOnly}>
              <Feather name={"heart"} size={24} color={theme.colors.tealBlue} />
              <Spacing horizontal={1} />
              <Text
                testID={testID + "pulse"}
                h3
                style={textStyles.colorDarkBlue}
              >
                {reading.pulse}
                <Text bodySmall style={textStyles.colorDarkBlue}>
                  {" "}
                  {LocalizedStrings.common.units.bpm}
                </Text>
              </Text>
            </View>
          </View>
        )}
        {qualifierReasonString !== undefined && (
          <View style={[styles.row]}>
            <Chip
              title={qualifierReasonString}
              color={theme.colors.tealBlue + "24"}
            />
          </View>
        )}
      </View>
      <View style={styles.alignEnd}>
        <Text
          testID={testID + "measure-date"}
          bodySmall
          style={textStyles.colorDarkBlue}
        >
          {DateTime.fromSeconds(reading.measure_timestamp).toFormat("MMM dd")}
        </Text>
        <Text
          testID={testID + "measure-time"}
          bodySmall
          style={textStyles.colorDarkBlue}
        >
          {DateTime.fromSeconds(reading.measure_timestamp).toFormat("hh:mm a")}
        </Text>
      </View>
    </View>
  );
};

const ReadingsHistoryScreen = () => {
  const route =
    useRoute<RouteProp<ReadingsStackParamList, "ReadingsHistory">>();
  const { theme } = useTheme();
  const styles = useStyles();
  const textStyles = useTextStyles();
  const viewStyles = useViewStyles();

  const { type } = useScreenType();

  const { readingType: readingTypeEncoded, days } = route.params;

  const readingType = DeviceType_url_decode(readingTypeEncoded);

  const defaultDateFrom = DateTime.now().minus({ days }).startOf("day");
  const defaultDateTo = DateTime.now().endOf("day");

  const [dateFrom, setDateFrom] = useState<DateTime>(defaultDateFrom);
  const [dateTo, setDateTo] = useState<DateTime>(defaultDateTo);

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [chartViewWidth, setChartViewWidth] = useState<number>();
  const [componentWidth, setComponentWidth] = useState<number>();

  const onDateRangeSelected = (from: DateTime, to: DateTime) => {
    setDateFrom(from);
    setDateTo(to);
  };

  const onClear = useCallback(() => {
    setDateFrom(defaultDateFrom);
    setDateTo(defaultDateTo);
  }, [defaultDateFrom, defaultDateTo]);

  const isOriginalDateRange =
    dateTo.toMillis() === defaultDateTo.toMillis() &&
    dateFrom.toMillis() === defaultDateFrom.toMillis();

  const { data: patient, isSuccess } = useGetAuthenticatedMember();

  const isDateRangeSet = dateFrom != undefined && dateTo != undefined;

  const {
    data,
    isLoading,
    isFetching,
    isUninitialized,
    refetch,
    isSuccess: getReadingsSuccess,
    fulfilledTimeStamp
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient ? patient.patient.patient_id : "",
      paramsObject: {
        device_type: readingType,
        sort: "measure_timestamp,DESC",
        startdate: Math.round(dateFrom?.toSeconds()),
        enddate: Math.round(dateTo?.toSeconds())
      }
    },
    {
      skip: patient === undefined
    }
  );

  const [isFocused, setIsFocused] = useState<boolean>(false);

  useFocusEffect(
    useCallback(() => {
      // Do something when the screen is focused
      setIsFocused(true);

      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
        setIsFocused(false);
      };
    }, [])
  );

  useEffect(() => {
    // only try to refetch if we have already fetched once
    const now = DateTime.now().toMillis();
    if (
      getReadingsSuccess &&
      isFocused &&
      // only refetch if it has been more than 1 minute since last fetch
      fulfilledTimeStamp &&
      now - fulfilledTimeStamp > 1000 * 60
    ) {
      // we need to do this because mobile screens don't unmount when you navigate away and are still on the same stack
      refetch();
    }
  }, [isFocused, fulfilledTimeStamp]);

  const accessor =
    readingType === DeviceTypeEnum.BLOOD_PRESSURE
      ? ["systolic", "diastolic"]
      : ["glucose"];
  const unit =
    readingType === DeviceTypeEnum.BLOOD_PRESSURE
      ? LocalizedStrings.common.units.mmHg
      : LocalizedStrings.common.units.glucoseUnit;

  const onLayout = (event: LayoutChangeEvent) => {
    const viewWidth = event.nativeEvent.layout.width;
    setChartViewWidth(viewWidth);
  };

  const handleRefresh = () => {
    if (isUninitialized) return;
    refetch();
  };

  const ListHeader = useMemo(
    () => (
      <View>
        <Spacing vertical={4} />
        <View style={styles.row}>
          <View style={styles.flex1}>
            <Text h1 style={textStyles.colorDarkGreyBlue}>
              {readingType === DeviceTypeEnum.BLOOD_PRESSURE
                ? LocalizedStrings.common.bloodPressure
                : LocalizedStrings.common.bloodSugar}
            </Text>
          </View>
          {isLoading && <ActivityIndicator />}
          {type !== ScreenTypeEnum.DESKTOP && (
            <IconButton
              icon="calendar"
              buttonSize={40}
              iconSize={24}
              shape="square"
              iconColor={theme.colors.white}
              color={theme.colors.tealBlue}
              onPress={() => {
                setModalVisible(true);
              }}
            />
          )}
        </View>

        {isDateRangeSet && <Spacing vertical={4} />}

        {isDateRangeSet && (
          <View style={styles.row}>
            <Text
              h4
              allowFontScaling={false}
              style={[
                textStyles.colorDarkGreyBlue,
                styles.dateFontSize,
                styles.flex1
              ]}
              {...AccessibilityHelper_getAccessibilityProps("Period")}
            >
              {`${dateFrom.toFormat("MMM dd, yyyy")} - ${dateTo.toFormat(
                "MMM dd, yyyy"
              )}`}
            </Text>

            {!isOriginalDateRange && type !== ScreenTypeEnum.DESKTOP && (
              <TouchableOpacity onPress={onClear} accessibilityLabel={"Reset"}>
                <Text link>{LocalizedStrings.common.reset}</Text>
              </TouchableOpacity>
            )}
          </View>
        )}

        <Spacing vertical={2} />

        {isDateRangeSet && isSuccess ? (
          <View
            onLayout={onLayout}
            style={[
              styles.flex1,
              type === ScreenTypeEnum.DESKTOP && viewStyles.cardContainer
            ]}
          >
            {chartViewWidth !== undefined && (
              <>
                <Spacing vertical={4} />
                <Chart
                  data={data}
                  hasAxis={true}
                  width={
                    chartViewWidth + (type === ScreenTypeEnum.DESKTOP ? 0 : 40)
                  }
                  height={type === ScreenTypeEnum.PHONE ? 250 : 400}
                  accessors={accessor as ReadingAccessorType[]}
                  accessorsMargin={{ x: 0, y: 20 }}
                  unit={unit}
                />
              </>
            )}

            {type === ScreenTypeEnum.DESKTOP && (
              <View style={[styles.rowOnly, { gap: 20 }]}>
                <Button
                  containerStyle={styles.flex1}
                  title={LocalizedStrings.common.clear}
                  type="outline"
                  onPress={onClear}
                />
                <Button
                  containerStyle={styles.flex1}
                  title={LocalizedStrings.screens.readingsHistory.openCalendar}
                  type="outline"
                  onPress={() => setModalVisible(true)}
                />
              </View>
            )}

            {data?.length > 2 && (
              <Text body style={textStyles.colorDarkGreyBlue}>
                {LocalizedStrings.screens.readingsHistory.historyText}
              </Text>
            )}
            <Spacing vertical={2} />
          </View>
        ) : (
          <Spacing vertical={4} />
        )}
      </View>
    ),
    [
      data,
      accessor,
      isFetching,
      dateFrom,
      dateFrom,
      isDateRangeSet,
      isSuccess,
      chartViewWidth,
      readingType
    ]
  );

  const RenderFlatList = ({ renderHeader = false }) => {
    return (
      <FlatList<DeviceReadingType>
        refreshControl={
          <RefreshControl
            refreshing={isLoading === false && isFetching}
            onRefresh={handleRefresh}
          />
        }
        data={data}
        contentContainerStyle={styles.container}
        renderItem={({ item, index }) => (
          <ListItem
            testID={"test-list-" + index + "-"}
            readingType={readingType}
            reading={item}
          />
        )}
        ListHeaderComponent={renderHeader ? ListHeader : ListFooterAndSeparator}
        ListFooterComponent={ListFooterAndSeparator}
        ItemSeparatorComponent={ListFooterAndSeparator}
      />
    );
  };

  return (
    <ScreenContainer
      onLayout={(event) => {
        setComponentWidth(event.nativeEvent.layout.width);
      }}
    >
      {type === ScreenTypeEnum.DESKTOP && componentWidth > 800 ? (
        <View style={styles.desktopContainer}>
          <View style={[styles.flex1, styles.headerDesktopContainer]}>
            {ListHeader}
          </View>
          <View style={styles.flex1}>
            <RenderFlatList />
          </View>
        </View>
      ) : (
        <RenderFlatList renderHeader />
      )}

      <DateRangePickerModal
        isVisible={modalVisible}
        mixpanelType={
          readingType === DeviceTypeEnum.BLOOD_PRESSURE
            ? "BPReadings"
            : "GlucoseReadings"
        }
        onCancel={() => setModalVisible(false)}
        onDateRangeSelected={(from: DateTime, to: DateTime) => {
          onDateRangeSelected(from.startOf("day"), to.endOf("day"));
          setModalVisible(false);
        }}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginLeft: 20,
      marginRight: 20,
      marginBottom: 20
    },
    flex1: {
      flex: 1
    },
    dateFontSize: {
      fontSize: 18
    },
    desktopContainer: {
      flex: 1,
      flexDirection: "row"
    },
    headerDesktopContainer: {
      backgroundColor: theme.colors.grey5,
      padding: 20
    },
    chartDesktopContainer: {},
    rowOnly: {
      flexDirection: "row",
      alignItems: "center"
    },
    row: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    alignEnd: { alignItems: "flex-end" }
  };
});

export default ReadingsHistoryScreen;
