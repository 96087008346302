import { createNativeStackNavigator } from "@react-navigation/native-stack";

import useScreenType from "../../hooks/useScreenType";
import {
  AppointmentsNavigationFlow,
  AppointmentsNavigationType,
  Header,
  ScreenOrientation
} from "../NavigationUtils";

const Stack = createNativeStackNavigator<AppointmentsNavigationType>();
const AppointmentsStackNavigator = ({ navigation, route }) => {
  const { type } = useScreenType();

  return (
    <Stack.Navigator
      initialRouteName="MyAppointments"
      screenOptions={{
        header: Header,
        orientation: ScreenOrientation(type)
      }}
    >
      {AppointmentsNavigationFlow(Stack)}
    </Stack.Navigator>
  );
};

export default AppointmentsStackNavigator;
