import { View } from "react-native";

import { makeStyles } from "@rneui/themed";

import Button from "../../components/ui/Button";
import ScreenContainer from "../../components/ui/ScreenContainer";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";

const ReauthenticationScreen = ({ navigation, route }) => {
  const insets = useSafeAreaInsets();
  const textStyles = useTextStyles();
  const styles = useStyles(insets);

  const { userName } = route.params;

  return (
    <ScreenContainer
      innerStyle={styles.container}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <View style={styles.flex1}>
        <Text h1 style={textStyles.colorDarkGreyBlue}>
          {LocalizedStrings.screens.reauthentication.welcome}
          {userName}!
        </Text>
        <Spacing vertical={4} />
        <Text bodyLarge style={textStyles.colorGreyBlue}>
          {LocalizedStrings.screens.reauthentication.notActive}
        </Text>
      </View>

      <Button
        containerStyle={styles.containerButton}
        title={LocalizedStrings.screens.reauthentication.button}
        onPress={() => navigation.navigate("Landing")}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  return {
    flex1: {
      flex: 1
    },
    container: {
      margin: 20,
      justifyContent: "space-evenly"
    },
    containerButton: {
      margin: 10,
      marginBottom: Math.max(insets.bottom, 10),
      gap: 10
    }
  };
});

export default ReauthenticationScreen;
